/**
 * @license
 *
 * Font Family: General Sans
 * Designed by: Frode Helland
 * URL: https://www.fontshare.com/fonts/general-sans
 * © 2022 Indian Type Foundry
 *
 * Font Styles:
 * General Sans Variable(Variable font)
 * General Sans Variable Italic(Variable font)
 * General Sans Extralight
 * General Sans Extralight Italic
 * General Sans Light
 * General Sans Light Italic
 * General Sans Regular
 * General Sans Italic
 * General Sans Medium
 * General Sans Medium Italic
 * General Sans Semibold
 * General Sans Semibold Italic
 * General Sans Bold
 * General Sans Bold Italic
 *
*/


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:

* 'wght' (range from 200.0 to 700.0)

*/

@font-face {
  font-family: 'General Sans';
  src: url('../fonts/GeneralSans-Variable.woff2') format('woff2'),
    url('../fonts/GeneralSans-Variable.woff') format('woff'),
    url('../fonts/GeneralSans-Variable.ttf') format('truetype');
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:

* 'wght' (range from 200.0 to 700.0)

*/

@font-face {
  font-family: 'General Sans';
  src: url('../fonts/GeneralSans-VariableItalic.woff2') format('woff2'),
    url('../fonts/GeneralSans-VariableItalic.woff') format('woff'),
    url('../fonts/GeneralSans-VariableItalic.ttf') format('truetype');
  font-weight: 200 700;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'General Sans';
  src: url('../fonts/GeneralSans-Extralight.woff2') format('woff2'),
    url('../fonts/GeneralSans-Extralight.woff') format('woff'),
    url('../fonts/GeneralSans-Extralight.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'General Sans';
  src: url('../fonts/GeneralSans-ExtralightItalic.woff2') format('woff2'),
    url('../fonts/GeneralSans-ExtralightItalic.woff') format('woff'),
    url('../fonts/GeneralSans-ExtralightItalic.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'General Sans';
  src: url('../fonts/GeneralSans-Light.woff2') format('woff2'),
    url('../fonts/GeneralSans-Light.woff') format('woff'),
    url('../fonts/GeneralSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'General Sans';
  src: url('../fonts/GeneralSans-LightItalic.woff2') format('woff2'),
    url('../fonts/GeneralSans-LightItalic.woff') format('woff'),
    url('../fonts/GeneralSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'General Sans';
  src: url('../fonts/GeneralSans-Regular.woff2') format('woff2'),
    url('../fonts/GeneralSans-Regular.woff') format('woff'),
    url('../fonts/GeneralSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'General Sans';
  src: url('../fonts/GeneralSans-Italic.woff2') format('woff2'),
    url('../fonts/GeneralSans-Italic.woff') format('woff'),
    url('../fonts/GeneralSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'General Sans';
  src: url('../fonts/GeneralSans-Medium.woff2') format('woff2'),
    url('../fonts/GeneralSans-Medium.woff') format('woff'),
    url('../fonts/GeneralSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'General Sans';
  src: url('../fonts/GeneralSans-MediumItalic.woff2') format('woff2'),
    url('../fonts/GeneralSans-MediumItalic.woff') format('woff'),
    url('../fonts/GeneralSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'General Sans';
  src: url('../fonts/GeneralSans-Semibold.woff2') format('woff2'),
    url('../fonts/GeneralSans-Semibold.woff') format('woff'),
    url('../fonts/GeneralSans-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'General Sans';
  src: url('../fonts/GeneralSans-SemiboldItalic.woff2') format('woff2'),
    url('../fonts/GeneralSans-SemiboldItalic.woff') format('woff'),
    url('../fonts/GeneralSans-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'General Sans';
  src: url('../fonts/GeneralSans-Bold.woff2') format('woff2'),
    url('../fonts/GeneralSans-Bold.woff') format('woff'),
    url('../fonts/GeneralSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'General Sans';
  src: url('../fonts/GeneralSans-BoldItalic.woff2') format('woff2'),
    url('../fonts/GeneralSans-BoldItalic.woff') format('woff'),
    url('../fonts/GeneralSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}