@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./phone-input-override";
@import "./datepicker-input-override";
@import "./react-table";
@import "./font.scss";

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

body {
  margin: 0;
  font-family: "General Sans", "font-sans", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: #273143;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Prescriptio-Card {
  box-shadow: -2px 4px 25px #eeeee9 !important;
}

.arrowr {
  font-size: 0.7rem !important;
}

.dash-body {
  position: relative !important;
  display: grid !important;
  grid-template-rows: 11% auto !important;
  @media (max-width: 1023px) {
    display: block !important;
  }
}

.dashh {
  position: relative !important;
  width: 100% !important;
  display: grid !important;
  grid-template-columns: 20% 80% !important;
  grid-template-rows: 10% 90% !important;
  @media (max-width: 1023px) {
    display: block !important;
  }
}

.sidebar2 {
  z-index: 100 !important;
}

.sidebar-provider {
  z-index: 100 !important;
}

#tip-img {
  @media (max-width: 1023px) {
    width: 100% !important;
    object-fit: cover !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
}

.e-empty {
  @media (max-width: 1023px) {
    background-color: rgba(207, 212, 208, 0.3);
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.arrow-icon-m {
  font-size: 0.7rem;
  color: #87928a;
}

.MuiTabs-scroller {
  @media (max-width: 1023px) {
    overflow-x: scroll !important;
  }
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  @media (max-width: 1023px) {
    width: 40% !important;
  }
}

.css-1aquho2-MuiTabs-indicator {
  @media (max-width: 1023px) {
    font-size: 14px !important;
  }
}

.ment-m {
  display: grid;
  grid-template-columns: 40% 56%;
  gap: 4%;
  grid-template-rows: auto;
  overflow: auto;
  height: fit-content;
  position: relative;
  width: 100%;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  @media (max-width: 1023px) {
    display: flex !important;
    flex-wrap: wrap !important;
  }
}

.lg-dependent {
  @media (max-width: 1023px) {
    display: none !important;
  }
}

.tpc-header {
  @media (max-width: 1023px) {
    flex-wrap: wrap !important;
  }
}

.fc-day-sun {
  background-color: #f8f8f7 !important;
}
.fc-day-sat {
  background-color: #f8f8f7 !important;
}
.fc-toolbar h2 {
  display: inline !important;
  margin-right: 10px !important;
}

.fc-button {
  margin-bottom: 10px !important;
}

.fc-prev-button {
  background: white !important;
  border-color: white !important;
  color: #000 !important;
}

.fc-next-button {
  background: white !important;
  border-color: white !important;
  color: #000 !important;
}

.pagination {
  display: flex;
}

.pagination > .selected {
  display: none;
}

.pagination > .disabled {
  cursor: not-allowed !important;
  color: #cecfc5 !important;
  opacity: 0.5;
}

.pagination > li:first-child {
  margin-right: 25px;
}

.fc-daygrid-event {
  background: none !important;
  border: none !important;
}
.fc-daygrid-day-bg {
  height: 40px !important;
}

.MuiFormControl-root {
  width: 100% !important;
}

.printDoc {
  display: none;
}

@media print {
  .no-print {
    display: none;
  }
  .print {
    display: block;
  }
  .printDoc {
    display: block;
  }
}
