// .react-datepicker__input-container {
//   @apply w-full h-full;
// }

// .date-picker {
//   @apply rounded border border-gray-300 text-[#103C1B] focus:bg-white focus:border-gray-500  py-3 w-[478px] h-[46px] px-4;
// }


.react-datepicker__input-container {
  @apply w-full h-full;
}

.date-picker {
  @apply rounded text-[#103C1B] focus:bg-white focus:border-gray-500  py-3 w-full h-[40px] px-4 border-none;
}

.react-datepicker {
  min-height: 284px !important;
  max-height: 284px !important;
}

.react-datepicker-popper { 
  z-index: 9999 !important;
}
