.PhoneInput {
  @apply border-none;
}

.PhoneInputCountry {
  @apply w-[83px] pl-4 bg-gray-100;
}

.PhoneInputInput {
  @apply border border-none outline-none pt-[14px] px-0 pb-[13px] h-[40px] rounded-r-md;
}
